import React from 'react';
import { Layout } from 'antd';
import { withCookies } from 'react-cookie';
import CustomHeader from '../header.js';
import CustomFooter from '../footer.js';
import '../../styles/dashboard.css'
import mailerImage from '../../images/mailer_pic.jpg'
import rbzNotice from '../../images/EcoCash-RBZ-Notification2.jpg'
import rbzNotice2 from '../../images/EcoCash Remit Notice_29_June_2020.jpg'
import covid19Notice from '../../images/july-22-covid-19-notice.jpg'
import ramadanPromotionTnC from '../../images/ramadan-promotion-ts-&-cs.jpg'
import ramadanPromotion from '../../images/website-blog-visual.jpg'
import thathaPromotion from '../../images/thatha-promo.jpg'
import lesothoCorridorLaunch from '../../images/lesotho-corridor-launch.jpg'
import zeroFeeFeesPress from '../../images/zero-fees-press.jpg'
import sasaiAirtelUganda from '../../images/sasai-airtel-uganda-press.jpg'
import funeralCoverForSAAgents from '../../images/funeral-cover-sa-agents.jpg';
import Log from '../../utils/log.js';

const { Content, Header, Footer } = Layout;
const TAG = "Blog";

const data = [
  {
    id: 1,
    title: 'Sasai Fintech launches cross border money transfer service from South Africa to the rest of Africa',
    author: 'Niel Joubert',
    date: '27 February 2019',
    location: 'JOHANNESBURG',
    image: mailerImage
  },
  {
    id: 2,
    title: 'EcoCash RBZ Notice',
    author: 'Sasai Fintech',
    image: rbzNotice
  },
  {
    id: 3,
    title: `Ramadan Promotion`,
    author: 'Sasai Fintech',
    image: ramadanPromotion
  },
  {
    id: 4,
    title: `EcoCash RBZ Notice - 29 June 2020`,
    author: 'Sasai Fintech',
    image: rbzNotice2
  },
  {
    id: 5,
    title: 'Econet Shops Closure Notice - 22 July 2020',
    author: 'Sasai Fintech',
    image: covid19Notice 
  },
  {
    id: 6,
    title: 'Thatha Amathousand Promotion',
    author: 'Sasai Fintech',
    image: thathaPromotion 
  },
  {
    id: 7,
    title: 'Sasai Transforms Cross-Border Money Transfer Convenience into Lesotho',
    author: 'Sasai Fintech',
    image: lesothoCorridorLaunch 
  },
  {
    id: 8,
    title: 'Econet opens first free remittance corridors to Zimbabwe',
    author: 'Sasai Fintech',
    image: zeroFeeFeesPress 
  },
  {
    id: 9,
    title: 'SASAI MONEY TRANSFER EXPANDS FREE REMITTANCES OFFER TO ADDITIONAL AFRICAN MARKETS IN PARTNERSHIP WITH AIRTEL MONEY',
    author: 'Sasai Fintech',
    image: sasaiAirtelUganda 
  },
  {
    id: 10,
    title: 'Sasai Money Transfer Introduces Innovative Funeral Cover for Agents in South Africa',
    author: 'Sasai Fintech',
    image: funeralCoverForSAAgents 
  },
];

class Blog extends React.Component {

  constructor(props) {
    super(props)
    Log.d(TAG, "constructor() -- props=" + JSON.stringify(props))
  }

  componentDidMount() {
    document.title = "Blog"
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <Layout>
        <Header className="custom-header" style={{ 'position': 'fixed', 'width': '100%', 'zIndex': '1'}}>
          <CustomHeader {...this.props}/>
        </Header>
        <Content className="dashboard-body">
          {this.renderContent()}
        </Content>
        <Footer className="custom-footer">
          <CustomFooter/>
        </Footer>
      </Layout>
    )
  }

  // renderContent() {
  //   Log.d(TAG, "renderContent() -- storyId=" + this.props.match.params.storyId)
  //   if (Number(this.props.match.params.storyId) === 1) {
  //     return this.renderContent1();
  //   } else {
  //     return this.renderContent2();
  //   }
  // }

  renderContent() {
    Log.d(TAG, "renderContent() -- storyId=" + this.props.match.params.storyId)
    
    switch (Number(this.props.match.params.storyId)) {
      case 1:
        return this.renderContent1();
      case 2:
        return this.renderContent2();
      case 3:
        return this.renderContent3();
      case 4:
        return this.renderContent4();
      case 5:
        return this.renderContent5();
      case 6:
        return this.renderContent6();
      case 7:
        return this.renderContent7();
      case 8:
        return this.renderContent8();
      case 9:
        return this.renderContent9();
      case 10:
        return this.renderContent10();
      default:
        return this.renderContent1();
    }
  }

  renderContent1() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10vh'}}>
        <h3 className="stories-title">{data[this.props.match.params.storyId - 1].title}</h3>
        <img className="stories-image" src={data[this.props.match.params.storyId - 1].image} alt="stories"/>
        <span className="terms-item-child"><b className="terms-item-bold">JOHANNESBURG, 27 February 2019.</b> Sasai Fintech South Africaa member of the Econet Group has today launched an enhanced cross-border money transfer service from South Africa to the rest of Africa.</span>
        <span className="terms-item-child">Darlington Mandivenga, Group CEO for Sasai Fintech International, says although the remittances space has a number of players, Sasai is not “just another player”. “We do things differently and have grown our business by responding quickly to market needs,” says Mandivenga.</span>
        <span className="terms-item-child">“What we offer is informed by what customers want and we know through research that they want more than just the ability to send and receive money,” says Mandivenga, who has been leading Econet Group’s fintech business since 2011.</span>
        <span className="terms-item-child">Sasai which is recognized globally as an African champion for financial inclusion has a track record of developing and scaling fintech services including mobile money, mobile micro-insurance, payments and digital banking.</span>
        <span className="terms-item-child">Sasai’s value proposition has unique bolt-on services such as the Sasai Home Wallet and the Breadwinner services which offer remitting customer benefits that are not offered by its competitors.</span>
        <span className="terms-item-child">The Home Wallet service enables customers to open mobile money wallets in their countries of origin and send money into these wallets from anywhere in the world. These funds can be used to perform all mobile money wallet functions as if the customer was back home, including paying utility bills,buying groceries for loved ones, or paying school fees. This decreases the risk of abuse and the use of funds for purposes other than what the sender had intended, which is a major challenge in most African markets.</span>
        <span className="terms-item-child">The Breadwinner service is death benefit cover that pays out benefits to a customer’s designated beneficiary in their country of origin should the customer pass away. This benefit is available at no additional cost to the customer provided they use the Sasai remittance service consistently over a period of time.</span>
        <span className="terms-item-child">Mutsa Sibanda, COO for Sasai Fintech South Africa, says, “We believe that we have an unmatched end to end cross border money transfer service for this massive market”.</span>
        <span className="terms-item-child">According to the World Bank’s Migration and Development Brief, global remittances are estimated to have reached $700 billion in 2018.. The World Bank estimates that officially recorded remittances to sub-Saharan Africa reached $45 billion in 2018, with more than 200 million Africans living outside their home countries.</span>
        <span className="terms-item-child">According to FinMark Trust, there are approximately 4 million migrants from the SADC region living in South Africa. Total remittances from South Africa into the other SADC countries are estimated at R17 billion per annum.</span>
        <span className="terms-item-child">“Our strategy includes pervasive distribution through strong local partnerships both locally and in the receiving markets. In South Africa we have partnered with a leading national retailer with over 1,500 outlets and merchant aggregators who have a combined footprint of more than 160,000 formal and informal outlets that include spaza shops,” says Sibanda.</span>
        <span className="terms-item-child">According to Sibanda Sasai Fintech South Africa has an aggressive expansion plan and have identified an initial ten key countries which will be rolled out over the next six months. “The combination of market experience, skills, our partnerships and the technology infrastructure we have in South Africa enables us to move swiftly,” says Sibanda.</span>
        <span className="terms-item-child">Sasai Fintech provides unparalleled access to over 200 million mobile money wallets and 100m bank accounts across Africa which provide last mile access for remittances from South Africa, EU and the UK.</span>
        <span className="terms-item-child">The Sasai Group also offers international remittance services to all African countries under the brand name Sasai Money Transfer Limited in the UK.</span>
        <br/>
        <span className="terms-item-child"><b className="terms-item-bold">About Sasai Smartech</b></span>
        <span className="terms-item-child"><a href="https://cassavasmartech.com/">Sasai Smartech</a> is a diversified smartech group, with a mandate to use digital solutions to drive socio-economic development, and to improve the overall quality of life for all Africans. Sasai has evolved from a Fintech operation (primarily Mobile Money) in Zimbabwe to a full-fledged smartech business, constantly tapping into new opportunities to address everyday challenges through innovative, inclusive, stable and reliable digital solutions. The company has established a balanced portfolio of distinct, yet highly synergistic business pillars, namely FinTech, InsurTech, Social Payments, On-Demand Services, e-Commerce, AgriTech, HealthTech and EduTech. For more information, visit <a href="https://cassavasmartech.com/">https://cassavasmartech.com/</a>. Follow Sasai on Twitter <a href="https://twitter.com/cassavasmartech">@SasaiSmartech</a></span>
        <br/>
        <br/>
        <span className="terms-item-child"><b className="terms-item-bold">Media relations contact:</b></span>
        <span className="terms-item-child">Niel Joubert</span>
      </div>
    )
  }

  renderContent2() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10vh', alignItems: 'center'}}>
        <h3 className="stories-title">{data[this.props.match.params.storyId - 1].title}</h3>
        <img style={{ width: '60%', height: '50%', objectFit: 'contain'}} src={data[this.props.match.params.storyId - 1].image} alt="stories"/>
      </div>
    )
  }

  renderContent3() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h3 className="stories-title">{data[this.props.match.params.storyId - 1].title}</h3>
        <img style={{ width: '60%', height: '50%', objectFit: 'contain'}} src={data[this.props.match.params.storyId - 1].image} alt="stories"/>
        <img style={{ width: '60%', height: '50%', marginTop: '10vh', objectFit: 'contain'}} src={ramadanPromotionTnC} alt="stories"/>
      </div>
    )
  }

  renderContent4() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h3 className="stories-title">{data[this.props.match.params.storyId - 1].title}</h3>
        <img style={{ width: '60%', height: '50%', objectFit: 'contain'}} src={data[this.props.match.params.storyId - 1].image} alt="stories"/>
      </div>
    )
  }

  renderContent5() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <h3 className="stories-title">{data[this.props.match.params.storyId - 1].title}</h3>
        <img style={{ width: '60%', height: '50%', objectFit: 'contain'}} src={data[this.props.match.params.storyId - 1].image} alt="stories"/>
      </div>
    )
  }

  renderContent6() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10vh'}}>
        <h3 className="stories-title">{data[this.props.match.params.storyId - 1].title}</h3>
        <img className="stories-image" src={data[this.props.match.params.storyId - 1].image} alt="stories"/>
        <span className="terms-item-child"><b className="terms-item-bold">SASAI MONEY TRANSFER GROCERY VOUCHER COMPETITION RULES & T&C’s (“RULES/T&C’s”)</b></span>
        <span className="terms-item-child">1. This Sasai Money Transfer Grocery voucher competition (“the Promotional Competition”) is organized by Sasai Fintech (Pty) Ltd (the “Promoter”).</span>
        <span className="terms-item-child"><b className="terms-item-bold">2. The Promotional Competition is open to natural persons legally resident in South Africa over the age of 18 (eighteen) years (or are emancipated minors), (“Participant/s”), except any employee, director, member, partner, agent or consultant of the Promoter or Ackerman's, PEP, PnP, Makro, Game, Checkers, Shoprite, Boxer, Jumbo, Builders Warehouse, Cambridge or of either of their affiliates and/or associated companies, who may not participate in this Promotional Competition.</b></span>
        <span className="terms-item-child">3. By entering the Promotional Competition, all Participants and the winners agree to be bound by these rules/T&C’s, which will be interpreted by the Promoter, whose decision regarding any dispute will be final and binding. The Promoter reserves the right to amend, modify, change, postpone, suspend, extend or cancel this Promotional Competition and any giveaways or any aspect thereof, without notice at any time, for any reason which the Promoter reasonably deem necessary.</span>
        <span className="terms-item-child"><b className="terms-item-bold">4. The Promotional Competition runs from 26 October 2022 to 27 December 2022(“Promotional Competition Period”).</b></span>
        <span className="terms-item-child">5. A total of 99 grocery vouchers of R 1000 (One Thousand rand) each (“the Giveaway/s”) will be given away during the Promotional Competition period. The give-away will work as follows: 11 x R 1000 (One Thousand rand) vouchers will be given away each week during the Promotional Competition period.</span>
        <span className="terms-item-child">6. To enter the Promotional Competition:</span>
        <span className="terms-item-child"> 6.1 register to use the Sasai Money Transfer service (Sasai Money Transfer), via the Sasai Money Transfer App, Sasai Money Transfer website (moneytransfer.sasai.global) or an approved Sasai Money Transfer Agent. For details visit <a href="https://moneytransfer.sasai.global/">moneytransfer.sasai.global</a></span>
        <span className="terms-item-child">6.2 To qualify you must send an equivalent of USD50 (Fifty United Stated Dollars) or more in Rands with Sasai Money Transfer to a beneficiary in Zimbabwe, during the Promotional Competition period.
</span>
        <span className="terms-item-child">7. Winners will be randomly selected from the list of entrants and announced on the Sasai Money Transfer website and the following social media pages - Facebook - @SasaiMoneyTransfer; Twitter - @SasaiMoneyTransfer; LinkedIn – SasaiMoneyTransfer; and will also be contacted via SMS.</span>
        <span className="terms-item-child">8. Winners will be notified on how they may collect their giveaways from either Ackerman's, PEP, PnP, Makro, Game, Checkers, Shoprite, Boxer, Jumbo, Builders Warehouse, or Cambridge by prior arrangement with Sasai Fintech.</span>
        <span className="terms-item-child">9. The Giveaway is neither transferable nor redeemable for cash. The Promoter reserves the right to substitute the Giveaway with any other give-away item of comparable commercial value.</span>
        <span className="terms-item-child">10. No liability shall attach to the Promoter or Ackerman's, PEP, PnP, Makro, Game, Checkers, Shoprite, Boxer, Jumbo, Builders Warehouse, or Cambridge in relation to any claims, losses or liabilities for any loss of profit or any other direct and/or indirect, special or consequential loss arising out of any of the Giveaways.</span>
        <span className="terms-item-child">11. Should any dispute arise in relation to the interpretation of these promotional rules, the Promoter’s decision shall be final and no correspondence shall be entered into.</span>
        <span className="terms-item-child"><b className="terms-item-bold">12. All Participants and the winners, as the case may be, indemnify the Promoter, Ackerman's, PEP, PnP, Makro, Game, Checkers, Shoprite, Boxer, Jumbo, Builders Warehouse, or Cambridge and their respective affiliates and/or associated companies against any and all claims of any nature whatsoever arising out of and/or from their participation in any way howsoever in this Promotional Competition (including, as a result of any act or omission, whether as a result of negligence, misrepresentation, misconduct or otherwise on the part of the Promoter, Ackerman's, PEP, PnP, Makro, Game, Checkers, Shoprite, Boxer, Jumbo, Builders Warehouse, Cambridge and/or use of the Giveaways).</b></span>
        <span className="terms-item-child">13. The Promoter may require the winners to complete and submit an information disclosure agreement and indemnification to enable the Promoter to ensure compliance with these Rrles/T&C’s and the Consumer Protection Act 68 of 2008. Should any winner refuse or be unable to comply with this rule for any reason, such a winner will be deemed to have rejected the Giveaway and it shall revert back to the Promoter.</span>
        <span className="terms-item-child">14. The Promoter may require the winners to be identified, photographed and published in printed media, or to appear on radio and television, when accepting their prizes or after having received their prizes. The Promoter may use the uploaded images in whole or in part, and any reproductions thereof, worldwide for advertising, promotion, publicity or otherwise on any website for any media purpose and for any other lawful purpose whatsoever. The winners will be given the opportunity to decline to the publication of their images and to participate in the Promoter’s marketing material in so far as it relates to the Giveaway.</span>
        <span className="terms-item-child">15. By entering the Promotional Competition, the participants agree that the Promoter and its affiliates and/or associated companies may contact the participants via email and through social media platforms to deliver marketing communication regarding their products and promotional activities, provided that the Participants are given the opportunity to opt-out of receiving marketing communication at any time via the appropriate opt-out mechanisms provided by the Promoter for such purpose. The Promoter may also use the personal data collected from the participants as described in the Privacy Policy accessible at moneytransfer.sasai.global/privacy-policy and that such use may include transfers to the Promoter’s affiliates and third party service providers in any country.</span>
        <span className="terms-item-child">16. All queries in connection with this promotional competition should be directed to the Sasai Fintech Sasai Money Transfer Customer Care Line on +2721 200 8517.</span>
        <span className="terms-item-child">17. A copy of the competition rules is available at no cost to the participants on request.</span>
        <br/>
      </div>
    )
  }

  renderContent7() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10vh'}}>
        <h3 className="stories-title">{data[this.props.match.params.storyId - 1].title}</h3>
        <img className="stories-image" src={data[this.props.match.params.storyId - 1].image} alt="stories"/>
        <span className="terms-item-child"><b className="terms-item-bold">Johannesburg, South Africa, October 2023</b></span>
        <span className="terms-item-child">Sasai Money Transfer, a business of <a href="https://www.cassavatechnologies.com/">Cassava Technologies</a> and one of the fastest growing international money transfer operators has announced the expansion of its money transfer service from South Africa and the United Kingdom into Lesotho through a partnership with Econet Telecom Lesotho’s EcoCash Spache Fono (mobile money wallet).</span>
        <span className="terms-item-child">According to the World Bank, remittances in Lesotho account for up to 22,5% of the GDP with inflows from South Africa reaching $477 million per year while $3 million inflows come from the United Kingdom. Over 50% or these remittances into Lesotho are sent to rural areas.</span>
        <span className="terms-item-child">The Executive Head for Sasai Money Transfer South Africa, Lee Ann Lancaster said “ …with over a million Basotho’s living and working in various sectors of the South African economy including mining, agriculture and construction, as a cross border money transfer operator, we particularly considered it as a key mandate to offer them  a safe, secure, affordable and convenient way to send money back home. According to the World bank estimates, about 75% of remittances are used to put food on the table, cover medical expenses, school fees or housing expenses and  in times of crisis is also used to cover crop losses or family emergencies”.</span>
        <span className="terms-item-child">"We are therefore extremely humbled to be the first operator to be formally granted the ADLA in South Africa to launch cross border money transfer to Lesotho through our strategic partnership with Econet and Ecocash in Lesotho. Our service will reach more than 70% of Basotho families living in the rural areas who depend on this money to meet the day to day needs. The majority of the recipients are women" added Lancaster.</span>
        <span className="terms-item-child">The General Manager for EcoCash Lesotho, Ms Matikoe Letsie said, “We are super excited that we have finally launched this long awaited service with Sasai Money Transfer. Through our strategic partnership, we are well positioned to serve and meet the needs of the Basotho migrant communities in South Africa in ensuring that the much needed money their loved ones back home depend on gets to them quickly and affordably. With the added convenience of accessibility within community based outlets that Sasai offers, we are expecting to see widespread adoption and usage of our service. Here in Lesotho, we offer one of the widest distribution network that offers our valued beneficiaries convenient and most affordable access. The beneficiaries can receive and use the funds in their EcoCash Spache Fono wallets, or they can cash out at any of our registered cash out outlets which include Econet Retail outlets and the many EcoCash Agents we have across the country”.</span>
        <span className="terms-item-child">To start sending money with Sasai Money Transfer at lowest fees, customers can self-register on the <a href="https://play.google.com/store/apps/details?id=com.ecocash.saremit.production">Sasai Money Transfer Android App</a>, <a href="https://apps.apple.com/us/app/sasai-money-tranfer/id1455082083?ls=1">Sasai Money Transfer iOS App</a> or <a href="https://moneytransfer.sasai.global/">Website</a>. All a customer needs to register is any one of the following documents:</span>
        <ul>
          <li>Asylum seeker document or</li>
          <li>Foreign passport or</li>
          <li>Foreign ID or</li>
          <li>South African ID</li>
          <li>A working mobile number.</li>
        </ul>
        <span className="terms-item-child">A dedicated customer support team is available to assist customers on the following support channels:</span>
        <span><b className="terms-item-bold">South Africa</b></span>
        <span>Help Call: <a href="tel:+27212008517">+27 (0) 21 200 8517</a> or</span>
        <span>To Chat Click <a href="https://wa.me/+27611054898">https://wa.me/+27611054898</a></span>
        <br/>
        <span><b className="terms-item-bold">United Kingdom</b></span>
        <span>Help call: <a href="tel:+442030518189">+44 (0) 203 051 8189</a> or</span>
        <span>To Chat Click <a href="https://wa.me/+447951619537">https://wa.me/+447951619537</a></span>
        <br/>
        <span><a href="https://moneytransfer.sasai.global/about-us">About Sasai Money Transfer</a></span>
      </div>
    )
  }

  renderContent8() {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h3 className="stories-title">{data[this.props.match.params.storyId - 1].title}</h3>
          <img style={{ width: '60%', height: '50%', objectFit: 'contain'}} src={data[this.props.match.params.storyId - 1].image} alt="stories"/>
          <span className="terms-item-child"><b className="terms-item-bold">Econet opens first free remittance corridors to Zimbabwe</b></span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <span className="terms-item-child">Zimbabwe’s largest telecommunications and technology company, Econet Wireless, has achieved an Africa first by opening two free remittance corridors into the country, completely eliminating the cost to both sender and receiver.</span>
          <span className="terms-item-child">Econet has partnered with its sister company, Sasai Money Transfer, to enable individuals and companies in the United Kingdom and South Africa to send money into Zimbabwe free of charge, starting today (May 2, 2024). The receiver also cashes out of their EcoCash wallet for free.</span>
          <span className="terms-item-child">International remittances currently attract a charge of up to 15% for the sender, while the receiver is charged 3% when cashing-out.</span>
          <span className="terms-item-child">Econet Wireless Zimbabwe CEO Dr Douglas Mboweni explained that the latest decision is aimed at helping Zimbabweans to weather the acute challenges caused by the drought.</span>
          <span className="terms-item-child">The country recently declared a national disaster over a drought caused by the climate event known as El Niño, which has left more than 2.7 million people in need of food aid.</span>
          <span className="terms-item-child">“We hope Zimbabweans in the Diaspora will use the savings to send more money home to their families,” Dr Mboweni said.</span>
          <span className="terms-item-child">“The majority of remittances to Zimbabwe are conducted through these two main corridors (the UK and South Africa). We don’t have to pay any third parties on those corridors, because we rely on a sister company in the group, which agreed to help out,” he said.</span>
          <span className="terms-item-child">The Econet CEO said he hopes the move by Econet will encourage other companies facilitating remittances into Zimbabwe to reduce their fees, noting that it was time that remittances into Africa in general were brought down “to assist with the continent’s economic development agenda”.</span>
          <span className="terms-item-child">Dr Mboweni added that the initiative was in support of government efforts to increase diaspora remittances coming into the country through formal channels.</span>
          <span className="terms-item-child">Econet's extensive EcoCash agent network ensures that even people in remote rural areas have access to low-cost financial services, reducing the need for recipients to travel long distances to collect cash.</span>
          <span className="terms-item-child">Econet said Remittance transfers will be facilitated through the EcoCash US dollar wallet, which is accessible by dialling <a href="tel:*153#">*153#</a> on an EcoCash registered Econet line.</span>
          <span className="terms-item-child">Senders in the UK and South Africa can access Sasai Money Transfer by downloading the Sasai Money Transfer App on the Google Play Store and on the App Store.</span>
        </div>
      </>
    )
  }

  renderContent9() {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h3 className="stories-title">{data[this.props.match.params.storyId - 1].title}</h3>
          <img style={{ width: '60%', height: '50%', objectFit: 'contain'}} src={data[this.props.match.params.storyId - 1].image} alt="stories"/>
          {/* <span className="terms-item-child"><b className="terms-item-bold">Econet opens first free remittance corridors to Zimbabwe</b></span> */}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
          <span className="terms-item-child"><a href="https://moneytransfer.sasai.global/">SASAI MONEY TRANSFER</a>, a business of <a href="https://www.cassavatechnologies.com/">Cassava Technologies</a> and one of the fastest growing international money transfer operators, has partnered with Airtel Money to expand its revolutionary offer of free remittances to more African markets. The Zero Fees initiative, which was first launched in May 2024 by Sasai Money Transfer in partnership with Econet in Zimbabwe, is now being expanded with Airtel Money starting with Uganda, before being extended to other high-traffic remittance Airtel markets such as Ghana, Malawi, Nigeria, Kenya and Zambia. 
          This innovative offering eliminates remittance sending fees for users of the Sasai Money Transfer service in the United Kingdom and South Africa sending money to family and friends in Uganda. The partnership with Airtel Money Uganda ensures that the recipient also enjoys similar benefits by cashing out their funds for free from their Airtel Money wallet. Overall, this is an end-to-end customer proposition offering unique benefits of improved access and affordability to both the sender and receiver.</span>
          <span className="terms-item-child">The current reality in the African context is that informal remittances are still prevalent, by and large exceeding the volume of formal remittances flowing through official channels. This high level of informality presents a challenge for African migrants many of whom end up losing their hard-earned money to unscrupulous intermediaries. In line with the vision of a digitally connected future that leaves no African behind, Sasai is leading the charge of digitizing and formalizing remittances, and making the services more accessible and affordable for all Africans. The goal is to close the gap between formal and informal money transfers, and making sure that no one is excluded from these essential services.</span>
          <span className="terms-item-child">The Managing Director of Airtel Mobile Commerce Uganda Limited, Mr. Japhet Aritho said, <i>“We have partnered with Sasai Money Transfer on their innovative initiative that aligns with our own agenda of ensuring financial inclusivity for all Ugandans. This offer brings even more value to our customers, coinciding with our big consumer promotion, <b>Vroomula Amajja</b>, which offers customers a chance to win daily cash prizes, weekly Boda-Boda prizes and a Toyota Rav4 Hybrid by simply receiving remittances into their Airtel Money wallet.” </i>
          Sasai Money Transfer’s forerunning partnership on zero fees with EcoCash, Zimbabwe’s largest mobile money operator, has proven to be a resounding success. The zero fees offer has become very popular with senders in the UK and SA offering relief from the often-exorbitant remittances fees seen in the market. Resultantly, the initiative has been described by many as an “indispensable lifeline” for families back home.</span>
          <span className="terms-item-child"><a href="https://sasaifintech.com/">Sasai Fintech</a> CEO, Mr. Darlington Mandivenga said, <i>“The partnership with Airtel Money Uganda offers an important avenue into one of Africa’s largest remittance markets. As Sasai, this is where we want to be, ensuring we reach as many people as possible and ultimately leave no African behind. We are in a competitively unique position where we have been able to utilize our source market assets, operational efficiencies and group synergies, while collaborating with like-minded partners such as Airtel Money to deliver innovative offerings.” </i> 
          The Zero Fees initiative by Sasai marks another first in the remittances industry, where a Money Transfer Operator has been able to effectively collaborate with Mobile Money Operators to focus on a single goal: making money transfer services more affordable. In an industry where fees can be as high as 13%, the zero fees initiative holds enormous long-term potential to contribute to the formalization of international money transfers, the bulk of which still flow through unsafe and unreliable informal channels.</span>
        </div>
      </>
    )
  }

  renderContent10() {
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10vh'}}>
        <h3 className="stories-title">{data[this.props.match.params.storyId - 1].title}</h3>
        <img className="stories-image" src={data[this.props.match.params.storyId - 1].image} alt="stories"/>
        <span className="terms-item-child"><b className="terms-item-bold">Johannesburg, 21 November 2024</b></span>
        <span className="terms-item-child"><a href="https://moneytransfer.sasai.global/">Sasai Money Transfer</a>, a business of <a href="https://www.cassavatechnologies.com/">Cassava Technologies</a> and a leading international money transfer operator, is continuing on its mission to advance financial inclusion by launching  another first, an innovative funeral cover product designed specifically for its Money Transfer Agents in South Africa. The insurance product (EcoSure) is offered in partnership with Econet Zimbabwe. Through this offering, Sasai Money Transfer seeks to provide a financial safety net for its Agents and their families while addressing the unique challenges faced by Zimbabweans in the diaspora during times of bereavement.</span>
        <span className="terms-item-child">The funeral cover, which is directly linked to an agent performance on Sasai Money Transfer services, offers a significant cash benefit of up to $3,000 in the event of a covered agent’s death. Ultimately, this ensures that the families left behind are able to manage unforeseen funeral expenses without additional financial strain. This also helps protect other savings that might have which are meant for other important needs such as children’s education.</span>
        <span className="terms-item-child">Shepherd Hondoyemoto, Executive Head for Sasai Money Transfer said “ This is in staying true to our Group’s vision of not leaving no African behind. With this product, we are demonstrating that Money Transfer Business  is about more than just sending money home. What we always seek to address are the day-to-day needs and challenges in the communities we serve, and with this tailored funeral cover, we are empowering our loyal agents and giving them more value.” </span>
        <span className="terms-item-child">Commenting on the initiative, Gift Noko, Chief Insurance Officer at Econet Insurance said “Our goal is not just about providing coverage but also preserving dignity. This funeral cover offers peace of mind, allowing Sasai agents to focus on their work while knowing their families are protected in unforeseen circumstances.”</span>
        <span className="terms-item-child">With over 3 million Zimbabweans estimated to be living in South Africa, the reality of death in the diaspora cannot be denied, hence the need for suitable international funeral cover. For many, the emotional toll of losing a loved one in a foreign country is further compounded by the financial and logistical challenges of arranging cross-border repatriation and burial. Many breadwinners face the dual burden of managing daily expenses while preparing for emergencies. </span>
        <span className="terms-item-child">John Ndlovu, an active Sasai Money Transfer agent based in Johannesburg had this to say about the new product:</span>
        <span className="terms-item-child">“This funeral cover is life-changing for us as Sasai Agents.  I’ve seen  the distress which comes with death in a foreign land when a friend of mine passed away last  year and the family needed to  repatriate him back to Zimbabwe for burial. They faced serious challenges trying to raise the required funds and it took them over 2 weeks to finally put together the required cash. It was not a good situation at all”</span>
        <span className="terms-item-child">Francis Mukombewashe, echoed similar sentiments “I work long hours in various communities, often returning home late, and knowing my family won’t have to bear the financial and emotional burden of taking me home to Zimbabwe when the unfortunate happens  provides me with incredible peace of mind. This cover by Sasai ensures peace, dignity and relief for the family. I would not want a paupers funeral”.</span>
        <span className="terms-item-child">The Funeral Cover by Sasai Money Transfer follows its ground-breaking Zero Fees initiative, which was launched in May 2024 in partnership with Econet and EcoCash Zimbabwe. Plans are also underway to expand the funeral cover to other Sasai Agents in South Africa who are serving other key markets such as Ghana, Malawi, Nigeria, Kenya, and Zambia.</span>
        <span className="terms-item-child">Similarly, the product will be taken beyond South Africa to overseas countries such as the UK, US, and Australia, further demonstrating Sasai’s dedication to meeting the unique needs of the African diaspora and not leaving them behind.</span>
        </div>
      </>
    )
  }
}

export default withCookies(Blog)
