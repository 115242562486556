import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import NoMatch from './components/no-match.js';
import Home from './components/home.js';
import CashOutPoints from './components/cash-out-points.js';
import CashInPoints from './components/cash-in-points.js';
import FindAgent from './components/find-agent.js';
import Quote from './components/quote.js';
import SignUpMore from './components/SignUp/signup-more-info.js';
import Login from './components/Login/login.js';
import Partners from './components/Dashboard/partners.js';
import Tariffs from './components/Dashboard/tariffs.js';
import SendMoney from './components/Dashboard/send-money.js';
import FindAgents from './components/Dashboard/find-agents.js';

import ManageRecipient from './components/Recipient/manage-recipient.js';
import RecipientList from './components/Recipient/recipient-list.js';
import AddOns from './components/AddOns/addons.js';
import ManageBWBeneficiary from './components/AddOns/manage-beneficiary.js';

import SelectPaymentMethod from './components/Payment/select-payment.js';
import TopUp from './components/Payment/top-up.js';
import PrintForm from './components/Payment/print-form.js';
import IntegratedForm from './components/Payment/integrated-form.js';

import TransactionNumber from './components/Transaction/transaction-number.js';
import TransactionError from './components/Transaction/transaction-error.js';
import TransactionList from './components/Transaction/transaction-list.js';
import TransactionDetails from './components/Transaction/transaction-details.js';

import UpdateProfile from './components/User/update-profile.js';
import ResetPassword from './components/User/reset-password.js';
import ForgetPassword from './components/User/forget-password.js';
import Limits from './components/User/limits.js';

import AboutUs from './components/Dashboard/about-us.js';
import FAQ from './components/Dashboard/faq.js';
import GetHelp from './components/Dashboard/get-help.js';
import PrivacyPolicy from './components/Dashboard/privacy-policy.js';
import Blog from './components/Dashboard/blog.js';
import BlogContent from './components/Dashboard/blog-content.js';
// import Terms from './components/Dashboard/terms.js';
import TermsSelection from './components/Dashboard/terms-selection';
import DynamicTerms from './components/Dashboard/dynamic-terms.js';
import Promotions from './components/Dashboard/promotions.js';
import VerifyEmail from './components/User/verify-email.js';
import SasaiLogin from "./components/SasaiLogin";


// import BreadWinner from "./components/Dashboard/bread-winner-terms";
import BreadWinnerTermsAndConditions from './components/Dashboard/bread-winner-terms';
import BreadWinnerHomePage from "./components/Dashboard/bread-winner-home-page"
import CookiePolicy from './components/Dashboard/cookie-policy.js';

import BuyAirtime from './components/buy-airtime';
import SelectProductOption from './components/select-product-option';
import SelectAirtimeDataPaymentOption from './components/select-airtime-payment';
import GuestSelectAirtimeDataPaymentOption from './components/guest-select-airtime-payment';
import OrderDetails from './components/order-details';
import AddAirtimeRecipient from './components/add-airtime-recipient.js';
import EditAirtimeRecipient from './components/edit-airtime-recipient.js';
import AddAirtimeSenderDetails from './components/add-airtime-sender-details.js';
import addAirtimeRecipientDetails from './components/add-airtime-recipient-details.js';
import guestSelectProductOption from './components/guest-select-product-option.js';
import TermsPromotions from './components/Dashboard/terms-promotions.js';
import airtimeOtp from './components/airtime-otp.js';
import GuestTransactionList from './components/guest-transaction-list.js';
import GuestRecipientList from './components/guest-recipient-list.js';
import GuestTransactionDetails from './components/guest-transaction-details.js';
import NotificationList from './components/Notification/notification-list.js';
import MobileNumberLookUp from './components/NewRegistration/mobile-number-look-up.js';
import NameConfirmation from './components/NewRegistration/name-confirmation.js';
import PersonalDetails from './components/NewRegistration/personal-details.js';
import WhereYouLive from './components/NewRegistration/where-you-live.js';
import WelcomeRegistration from './components/NewRegistration/welcome-registration.js';

import Referral from './components/CustomerReferral/referral.js';
import FamilyAndFriends from './components/CustomerReferral/family-and-friends.js';
import FamilyAndFriendsDetails from './components/CustomerReferral/family-and-friends-details.js'
import ShareALinkOrCode from './components/CustomerReferral/share-a-link-or-code.js';
import MyRewards from './components/CustomerReferral/my-rewards.js';
import AirtimeDataRecipientDetails from './components/CustomerReferral/airtime-data-recipient-details.js';
import AirtimeDataChooseBundle from './components/CustomerReferral/airtime-data-choose-bundle.js';
import VoucherDetails from './components/CustomerReferral/voucher-details.js';
import ReferralRedeemedAmountSummary from './components/CustomerReferral/referral-redeemed-amount-summary.js';

import RemitHopeHomePage from "./components/RemitHope/remit-hope-home-page.js";
import RemitHopeCategory from "./components/RemitHope/remit-hope-category.js";
import RemitHopeCampaign from "./components/RemitHope/remit-hope-campaign.js";
import RemitHopePaymentOptions from "./components/RemitHope/remit-hope-payment-options.js";


export default () => (
  <BrowserRouter onUpdate={() => { }}>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path='/sasai-login' component={SasaiLogin} />
      <Route exact path='/sign-up' component={SignUpMore} />
      <Route exact path='/get-quote' component={Quote} />
      <Route exact path='/manage-recipient/:recipientID?' component={ManageRecipient} />
      <Route exact path='/recipient-list' component={RecipientList} />
      <Route exact path='/login' component={Login} />
      <Route exact path='/select-payment-method' component={SelectPaymentMethod} />
      <Route exact path='/top-up' component={TopUp} />
      <Route exact path='/transaction-number/:transactionID' component={TransactionNumber} />
      <Route exact path='/payment/error/:error?' component={TransactionError} />
      <Route exact path='/transaction-list' component={TransactionList} />
      <Route exact path='/update-profile' component={UpdateProfile} />
      <Route exact path='/dp/:country/password-reset/:uid/:token' component={ResetPassword} />
      <Route exact path='/form/:type/:token/:calculationID/:paymentMethodName/:sendingCountryName/:receivingCountryName/:beneficiaryName/:reason/:reasonName/:source/:transactionID?' component={PrintForm} />
      <Route exact path='/dp/:country/email-verify/:uid/:token' component={VerifyEmail} /> 
      <Route exact path='/transaction-details/:transactionID' component={TransactionDetails} />
      <Route exact path='/forget-password' component={ForgetPassword} />
      <Route exact path='/our-partners' component={Partners} />
      <Route exact path='/payment-form' component={IntegratedForm} />
      <Route exact path='/about-us' component={AboutUs} />
      <Route exact path='/faq' component={FAQ} />
      <Route exact path='/get-help' component={GetHelp} />
      <Route exact path='/privacy-policy' component={PrivacyPolicy} />
      <Route exact path='/find-agents' component={FindAgents} />
      <Route exact path='/blog' component={Blog} />
      <Route exact path='/blog/:storyId/:title' component={BlogContent} />
      <Route exact path='/how-it-works' component={SendMoney} />
      <Route exact path='/our-tariffs' component={Tariffs} />
      <Route exact path='/terms-of-use' component={TermsSelection} />
      <Route exact path='/terms-of-use/promotions/:id' component={TermsPromotions} />
      <Route exact path='/terms-of-use/:country' component={DynamicTerms} />
      <Route exact path='/cookie-policy' component={CookiePolicy} />
      <Route exact path='/breadwinner-terms-of-use' component={BreadWinnerTermsAndConditions} />
      <Route exact path='/find-agents/cash-out-Zimbabwe' component={CashOutPoints} />
      <Route exact path='/find-agents/cash-in-SouthAfrica' component={CashInPoints} />
      <Route exact path='/find-agent' component={FindAgent} />
      <Route exact path='/promotions' component={Promotions} />
      <Route exact path='/limits' component={Limits} />
      <Route exact path='/breadwinner-home' component={BreadWinnerHomePage} />
      <Route exact path='/breadwinner-policy' component={AddOns} />
      <Route exact path='/manage-breadwinner-policy' component={ManageBWBeneficiary} />
      <Route exact path='/send-airtime-data' component={BuyAirtime} />
      <Route exact path='/select-product-option' component={SelectProductOption} />
      <Route exact path='/order-details' component={OrderDetails} />
      <Route exact path='/airtime-data-payment' component={SelectAirtimeDataPaymentOption} />
      <Route exact path='/add-airtime-recipient' component={AddAirtimeRecipient} />
      <Route exact path='/edit-airtime-recipient/:recipientID' component={EditAirtimeRecipient} />
      {/* Dtone Guest Routes  */}
      <Route exact path='/airtime-otp' component={airtimeOtp} />
      <Route exact path='/airtime-sender-details' component={AddAirtimeSenderDetails} />
      <Route exact path='/airtime-recipient-details' component={addAirtimeRecipientDetails} />
      <Route exact path='/guest-select-product-option' component={guestSelectProductOption} />
      <Route exact path='/guest-airtime-data-payment' component={GuestSelectAirtimeDataPaymentOption} />
      <Route exact path='/guest-transactions' component={GuestTransactionList} />
      <Route exact path='/guest-transaction-details/:transactionID' component={GuestTransactionDetails} />
      <Route exact path='/guest-recipient-list' component={GuestRecipientList} />
      <Route exact path='/notification-list' component={NotificationList} />
      {/* New SA registration */}
      <Route exact path='/welcome-registration' component={WelcomeRegistration} />
      <Route exact path='/welcome-registration/:referenceCode' component={WelcomeRegistration} />
      <Route exact path='/look-up' component={MobileNumberLookUp} />
      <Route exact path='/name-confirmation' component={NameConfirmation} />
      <Route exact path='/personal-details' component={PersonalDetails} />
      <Route exact path='/where-you-live' component={WhereYouLive} />
      {/* Customer referral */}
      <Route exact path='/referral' component={Referral} />
      <Route exact path='/family-and-friends' component={FamilyAndFriends} />
      <Route exact path='/family-and-friends/:referralId' component={FamilyAndFriendsDetails} />
      <Route exact path='/share-a-link-or-code' component={ShareALinkOrCode} />
      <Route exact path='/my-rewards' component={MyRewards} />
      <Route exact path='/my-rewards/airtime-data/recipient-details' component={AirtimeDataRecipientDetails} />
      <Route exact path='/my-rewards/airtime-data/choose-bundle' component={AirtimeDataChooseBundle} />
      <Route exact path='/my-rewards/voucher/details' component={VoucherDetails} />
      <Route exact path='/redeemed-amount-summary' component={ReferralRedeemedAmountSummary} />
      {/* Remit Hope */}
      <Route exact path='/remit-hope' component={RemitHopeHomePage} />
      <Route exact path='/remit-hope/categories/:id' component={RemitHopeCategory} />
      <Route exact path='/remit-hope/categories/:id/campaigns/:campaignId' component={RemitHopeCampaign} />
      <Route exact path='/remit-hope/payment-options' component={RemitHopePaymentOptions} />
      <Route component={NoMatch} />
    </Switch>
  </BrowserRouter>
);
